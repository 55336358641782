
import { Vue, Component } from "vue-property-decorator";
import { AdminService } from "@/api/AdminServiceApi";
import { IDiscountCode } from "@/models/DiscountCode";
import InputField from "@/components/InputField.vue";

@Component({ components: { InputField } })
export default class DashboardInvoices extends Vue {
  discountCodes: Array<IDiscountCode> = [];

  limit: number = 10;
  offset: number = 0;
  currentPage: number = 1;
  pageSize: number = 0;

  discountCode: string = "";
  expiryDate: string = "";
  discountPercentage: number = 0;

  async created() {
    await AdminService.DiscountCodes()
      .then(async response => {
        this.discountCodes = response.data;
      })
      .catch(error => {});
  }

  get pagesCount(): number {
    return Math.ceil(this.discountCodes.length / this.limit);
  }

  get limitDisplay(): number {
    const discountCodesCount = this.discountCodes.length;
    if (this.limit > discountCodesCount) {
      return discountCodesCount;
    }
    return this.limit;
  }

  paginateNext() {
    this.resetState();
    this.currentPage++;
    this.offset += this.limit;
  }

  paginatePrevious() {
    this.resetState();
    this.currentPage--;
    this.offset -= this.limit;
  }

  resetState() {
    this.discountCodes = [];
  }

  async createNewDiscountCode() {
    await AdminService.CreateDiscountCode({
      code: this.discountCode,
      expires: this.expiryDate,
      discountPercentage: this.discountPercentage
    })
      .then(() => {
        this.$swal.fire({
          titleText: "Rabattkod skapad",
          confirmButtonText: "Ok",
          icon: "success"
        });
        AdminService.DiscountCodes()
          .then(async response => {
            this.discountCodes = response.data;
          })
          .catch(error => {});
      })
      .catch(() => {
        this.$swal.fire({
          titleText: "Någonting gick fel",
          confirmButtonText: "Ok",
          icon: "error"
        });
      });
  }
}
